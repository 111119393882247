(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAuth.controller:LogoutCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .controller('LogoutCtrl', LogoutCtrl);

  function LogoutCtrl(Auth, $state, OrderService, SessionService) {
    Auth.logout();
    OrderService.clearStorageItems();
    SessionService.clearSessionTimeout();
    $state.go('login');
  }
}());
